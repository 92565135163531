var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      directives: [
        {
          name: "c-tooltip",
          rawName: "v-c-tooltip.hover.click",
          value: _vm.tooltipDate,
          expression: "tooltipDate",
          modifiers: { hover: true, click: true }
        }
      ],
      attrs: { "data-toggle": "tooltip", "data-placement": "top" }
    },
    [
      _vm._v(" " + _vm._s(_vm.displayDate)),
      _vm.showDistance
        ? [_vm._v(" (" + _vm._s(_vm.formatDistance) + ")")]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }